*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
    background-color: white;
    font-size: 1vh;
    color: white;
    /* cursor: none; */
}

body {
    background-color: white;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    z-index: 100;
    font-size: 10px;
    box-sizing: border-box;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    width: 100vw;
    height: 100vh;
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


@media screen and (min-width: 1080px) {

}

@media screen and (max-width: 1080px) {
    .responsive {
        margin-bottom: 10vh;
    }
}

.webgl
{
    position: fixed;
    outline: none;
    box-sizing: border-box;
}

.mainSlider {
    height: 100vh;
    width: 100vw;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.section {
    height: 100vh;
    width: 100vw;
    /* border: 2px solid red; */
    z-index: 5000;
    padding: 5rem;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 50000;
    font-size: 1.5rem;
    pointer-events: none;
}

.link {
    pointer-events: auto;
    cursor: pointer;
    color: white;
}